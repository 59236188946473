<template>
  <v-app-bar
    v-show="visible"
    ref="appBar"
    :input-value.sync="visible"
    app
    :fixed="nav.fixed"
    :absolute="!nav.fixed"
    class="top-navigation"
    dense
    :extension-height="nav.tabs ? 32 : 0"
    tile
    :hide-on-scroll="hideOnScroll"
    :shrink-on-scroll="nav.shrinkOnScroll"
    :elevation="nav.elevation"
    :elevate-on-scroll="nav.elevateOnScroll"
    :scroll-threshold="nav.scrollThreshold"
    :color="nav.backgroundColor"
  >
    <v-container :class="{ 'px-0': $vuetify.breakpoint.smAndDown }">
      <v-row align="center">
        <v-col cols="3">
          <v-btn
            v-show="nav.showBackArrow"
            icon
            small
            :color="nav.color"
            @click="navigateBack()"
          >
            <PIcon
              :icon="$route.query.back ? 'close' : 'arrow-left'"
              :color="nav.color"
            />
          </v-btn>
        </v-col>
        <v-col cols="6" class="text-center">
          <h2 :class="`overline ${nav.color}--text`">
            {{ nav.title }}
          </h2>
        </v-col>
        <v-col cols="3" class="d-flex align-center justify-end">
          <NotificationHandler v-if="nav.showNotificationsIcon" />
          <v-btn
            v-if="nav.showListIcon"
            icon
            small
            :color="nav.color"
            :to="nav.listIconRoute"
            active-class="btn-not-active"
          >
            <PIcon icon="list-bulleted" :color="nav.color" />
          </v-btn>
          <v-btn
            v-if="nav.showAvatar"
            icon
            small
            :to="{ name: 'ProfileOverview' }"
            class="ml-2"
          >
            <UserAvatar
              size="24"
              color="white"
              :user-id="currentUser.id"
              disable-link
              :image="currentUser.avatar ? currentUser.avatar.img_url : ''"
            />
          </v-btn>
          <v-btn
            v-if="nav.showSettingsIcon"
            icon
            small
            :color="nav.color"
            :to="nav.settingsIconRoute"
            active-class="btn-not-active"
          >
            <PIcon icon="settings" :color="nav.color" />
          </v-btn>
          <v-btn
            v-show="nav.showCloseIcon"
            icon
            small
            :color="nav.color"
            @click="navigateBack()"
          >
            <PIcon icon="close" :color="nav.color" />
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <template v-if="nav.tabs" v-slot:extension>
      <v-container class="py-0">
        <v-tabs
          dark
          class="primary"
          :height="32"
          centered
          center-active
          hide-slider
        >
          <!-- :centered="$vuetify.breakpoint.smAndDown" -->
          <v-tab
            v-for="(tab, i) in nav.tabs"
            :key="i"
            :to="tab.route"
            :disabled="tab.disabled"
            class="font-weight-bold"
            active-class="active-tab"
          >
            <span class="overline">{{ $t(tab.title) }}</span>
          </v-tab>
        </v-tabs>
      </v-container>
    </template>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
import UserAvatar from "@/components/user/UserAvatar";
import NotificationHandler from "@/components/notifications/NotificationHandler";

export default {
  name: "TopNavigation",
  components: {
    UserAvatar,
    NotificationHandler,
  },
  data() {
    return {
      visible: true,
      hideOnScroll: false,
    };
  },
  computed: {
    ...mapGetters("auth", { currentUser: "get_current_user" }),
    ...mapGetters("app", { nav: "get_topnavigation_settings" }),
    publicImagePath() {
      return process.env.BASE_URL;
    },
  },
  watch: {
    "$route.path"() {
      this.visible = this.nav.visible;
      this.$refs.appBar.isActive = true;
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    navigateBack() {
      if (this.$route.query.back) {
        this.$router.push({ path: decodeURIComponent(this.$route.query.back) });
        return false;
      }
      if (
        this.nav.previousRoute.name === null ||
        ["Tutorial", "Quiz", "Quiz", "QuizPlay", "Play"].includes(
          this.nav.previousRoute.name
        )
      ) {
        this.$router.push({ name: "Dashboard" });
      } else {
        this.$router.back();
      }
    },
    handleScroll() {
      // TODO: check depricated props
      if (window.pageYOffset > this.nav.scrollThreshold) {
        this.hideOnScroll =
          window.pageYOffset > this.nav.scrollThreshold
            ? this.nav.hideOnScroll
            : false;
      } else this.hideOnScroll = false;
    },
  },
};
</script>

<style>
.top-navigation {
  z-index: 10 !important;
}
.btn-not-active::before {
  opacity: 0 !important;
}

.theme--dark.v-tabs > .v-tabs-bar {
  background: transparent !important;
}
.active-tab {
  background-color: rgba(255, 255, 255, 0.2);
}
</style>
