import { MEDIA_SERVER_URL } from "@/plugins/servers/Api.js";
import { Nugget } from "@/models/nuggets/Nugget";
export class Journey {
  id = -1;
  name = "";
  image = "";
  image_token = "";
  description = "";
  status = "";
  status_user = "";
  attempts = -1;
  attempts_left = -1;
  bundles_main = [];
  bundles_user = [];
  start_at = "";
  end_at = "";
  user_id = -1;
  user_journey_id = -1;
  user_bundle_id = -1;
  coins = -1;

  constructor(journeyObj, userJourneyObj) {
    this.id = journeyObj.id;
    this.name = journeyObj.name;
    this.image = journeyObj.image;
    this.image_token = journeyObj.image_token;
    this.description = journeyObj.description;
    this.status = journeyObj.status;
    this.attempts = journeyObj.attempts;
    this.bundles_main = journeyObj.bundles;
    this.start_at = journeyObj.start_at;
    this.end_at = journeyObj.end_at;
    this.coins = journeyObj.coins ? journeyObj.coins : 0;

    if (userJourneyObj) {
      this.user_id = userJourneyObj.user_id;
      this.user_journey_id = userJourneyObj.id;
      this.status_user = userJourneyObj.status;
      this.attempts_left = userJourneyObj.attempts_left;
      this.bundles_user = userJourneyObj.bundles;
    }
  }

  get bundles() {
    let newBundles = [];
    this.bundles_main.forEach((bundle, index) => {
      let newBundle = {
        id: bundle.id,
        user_bundle_id:
          this.user_id !== -1 ? this.bundles_user[index].id : null,
        name: bundle.name,
        description: bundle.description,
        journey_name: this.name,
        image: bundle.image,
        image_token: bundle.image_token,
        image_display: this.getBundleImage(bundle.image_token),
        level: bundle.level,
        nuggets_count: this.getBundleNuggetCount(bundle.steps),
        quizzes_count: this.getBundleQuizzesCount(bundle.steps),
        steps: this.user_id !== -1 ? this.bundles_user[index].steps : [],
        status:
          this.user_id !== -1 ? this.bundles_user[index].status : "locked",
        criterias:
          this.user_id !== -1 ? this.bundles_user[index].criterias : null,
      };
      newBundle.steps = this.generateBundleSteps(newBundle.steps);
      newBundles.push(newBundle);
    });
    return newBundles;
  }

  get imageUrl() {
    if (!this.image || this.image == "") return "/cdn/images/default.jpg";
    return `${MEDIA_SERVER_URL}/i/${this.image_token}`;
  }

  getBundleImage(token) {
    if (!token || token == "") return "/cdn/images/default.jpg";
    return `${MEDIA_SERVER_URL}/i/${token}`;
  }

  generateBundleSteps(steps) {
    var newSteps = [];
    var lastOrder = 0;
    steps
      .sort((a, b) => a.origin.order - b.origin.order)
      .forEach((step) => {
        if (step.origin.order === lastOrder) return false;
        if (step.origin.type_id === 1) {
          step.nugget = new Nugget(step.origin.nugget);
          step.type = "nugget";
        }
        if (step.origin.type_id === 2) {
          step.type = "quiz";
          step.quiz = {};
          step.quiz.id = step.game_id;
        }
        newSteps.push(step);
        lastOrder = step.origin.order;
      });
    return newSteps;
  }

  getBundleNuggetCount(steps) {
    return steps.filter((s) => s.type === "nugget").length;
  }

  getBundleQuizzesCount(steps) {
    return steps.filter((s) => s.type === "quiz").length;
  }
}
